.zone {
  position: absolute;
  width: 50%;
  height: 140%;
  left: -25%;
  top: -20%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 999;
  transition: background-color 250ms;
  border-radius: 6px;
}

.last {
  left: auto;
  right: -25%;
}

.hover {
  background-color: rgba(229, 229, 229, 0.4);
  box-shadow: 0 0 5px 0 rgba(229, 229, 229, 0.4);
}
