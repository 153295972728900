.hero {
  position: relative;
  margin: 0 -20px;
  padding: 20px;
  background-size: cover;
  background-position: center;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  text-shadow: 0 0 8px rgba(20, 20, 20, 0.8);
  z-index: 99;
}

.title {
  margin: 0 0 20px;
}

.button {
  display: flex;
  background: rgba(20, 20, 20, 0.5);
  border: none;
  border-radius: 3px;
  padding: 10px 8px;
  color: #e5e5e5;
  width: 140px;
  font-size: 20px;
  justify-content: space-around;
  outline: none;
}

@media (min-width: 768px) {
  .container {
    max-width: 600px;
  }

  .hero {
    margin: 0 -40px;
    padding: 40px;
    min-height: 50vw;
  }

  .title {
    font-size: 50px;
  }

  .description {
    max-width: 400px;
    font-size: 18px;
  }
}

@media (min-width: 1800px) {
  .container {
    justify-content: center;
  }
}

.video {
  position: absolute;
  top: 0;
  left: 0;
}

.gracefullyLeaving {
  opacity: 0 !important;
}
