@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap');

.logo {
  display: flex;
  align-items: flex-start;
  color: #e31b24;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin: 0 10px;
}

@media (min-width: 768px) {
  .logo {
    font-size: 24px;
    margin: 0 20px;
  }
}

.n,
.e,
.t,
.f,
.l,
.i,
.x {
  line-height: 1;
}

.n,
.x {
  font-size: 115%;
}

.i,
.e {
  font-size: 105%;
}
