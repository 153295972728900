html {
  background-color: #141414;
  color: #e5e5e5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  /* Override default body spacing. */
  margin: 0;
}

*,
:after,
:before {
  /* Box-sizing on all elements for intuitive width/height. */
  box-sizing: border-box;
}

a {
  text-decoration: none;
  transition: color 250ms;
  color: #e5e5e5;
}
a:visited {
  color: inherit;
}
a:hover {
  color: #b3b3b3;
}

button {
  cursor: pointer;
}
