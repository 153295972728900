.video {
  display: none;
  width: 100%;
  height: 100%;
  object-fit: fill;
  opacity: 0;
  transition: opacity 1.5s;
}

.enter {
  opacity: 1;
}

@media (min-width: 768px) {
  .video {
    display: block;
  }
}
