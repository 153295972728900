.selector {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  min-height: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fade-in 400ms;
}

.header {
  font-size: 30px;
}

@media (min-width: 768px) {
  .selector {
    top: 70px;
  }

  .header {
    font-size: 50px;
    margin-bottom: 30px;
  }
}

.profiles {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
}

@media (min-width: 321px) {
  .profiles {
    grid-template-columns: auto auto auto;
  }
}

@media (min-width: 768px) {
  .profiles {
    grid-column-gap: 20px;
  }
}

.button {
  padding: 10px 0;
  margin: 0;
  color: #808080;
  border: none;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  outline-color: #b3b3b3;
}

.avatar {
  font-size: 100px;
  line-height: 1;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .button {
    font-size: 18px;
  }

  .avatar {
    font-size: 120px;
    margin-bottom: 5px;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(1.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
