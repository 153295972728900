.nav {
  display: flex;
  padding: 10px;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .nav {
    padding: 20px;
  }
}

.mainLinks {
  display: flex;
  align-items: center;
}

.active {
  font-weight: bold;
}

.navLink {
  margin: 0 10px;
}

.avatar {
  font-size: 25px;
  line-height: 1;
}
