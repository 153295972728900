.movie {
  position: relative;
  width: 220px;
  height: 125px;
  margin-right: 5px;
  transition: transform 200ms;
}

@media (min-width: 768px) {
  .movie.hover {
    box-shadow: 0 0 5px 1px rgba(20, 20, 20, 0.75);
    transform: scale(1.3);
    z-index: 1;
  }
}

.moveIt {
  cursor: move;
}

.title {
  position: absolute;
  padding: 10px 4px 4px;
  margin: 0;
  background: linear-gradient(
    180deg,
    rgba(20, 20, 20, 0) 0%,
    rgba(20, 20, 20, 0.5) 50%,
    rgba(20, 20, 20, 0.75) 100%
  );
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 14px;
}

.thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.button {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  padding: 5px;
  margin: 0;
  outline: none;
}

.beginningButton {
  position: absolute;
  top: 30px;
  right: 0;
  background: none;
  border: none;
  padding: 5px;
  margin: 0;
}

@media (min-width: 768px) {
  .button {
    visibility: hidden;
    outline: none;
  }

  .beginningButton {
    visibility: hidden;
  }

  .movie:hover .button {
    visibility: visible;
  }
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: fade-in 1s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
