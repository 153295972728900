.myList {
  margin: 0 20px;
}

.noTitlesYet {
  margin-top: 60px;
  font-size: 20px;
}

@media (min-width: 768px) {
  .myList {
    margin: 0 40px;
  }

  .noTitlesYet {
    text-align: center;
  }
}
