.category {
  margin-bottom: 40px;
}

.movies {
  overflow-x: auto;
  overflow-y: hidden;
  margin: 0 -20px;
  /* For hiding scrollbar in firefox. */
  scrollbar-width: none;
}
.movies::-webkit-scrollbar {
  /* For hiding scrollbar in webkit. */
  width: 0;
}

.moviesInner {
  height: 190px;
  display: inline-flex;
  margin: -10px 0;
  padding: 0 20px;
  align-items: center;
}

@media (min-width: 768px) {
  .movies {
    margin: 0 -40px;
  }
  .moviesInner {
    padding: 0 40px;
  }
}

.movieWrapper {
  position: relative;
}

@media (max-width: 767px) {
  .stackedOnMobile .movies {
    margin: auto;
  }

  .stackedOnMobile .moviesInner {
    height: auto;
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  .stackedOnMobile .movieWrapper {
    margin: 10px 0;
  }
}
