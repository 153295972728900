@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap);
html {
  background-color: #141414;
  color: #e5e5e5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  /* Override default body spacing. */
  margin: 0;
}

*,
:after,
:before {
  /* Box-sizing on all elements for intuitive width/height. */
  box-sizing: border-box;
}

a {
  text-decoration: none;
  -webkit-transition: color 250ms;
  transition: color 250ms;
  color: #e5e5e5;
}
a:visited {
  color: inherit;
}
a:hover {
  color: #b3b3b3;
}

button {
  cursor: pointer;
}

.Nav_nav__2q5jM {
  display: flex;
  padding: 10px;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .Nav_nav__2q5jM {
    padding: 20px;
  }
}

.Nav_mainLinks__1Lg9i {
  display: flex;
  align-items: center;
}

.Nav_active__1PD6c {
  font-weight: bold;
}

.Nav_navLink__1OTwo {
  margin: 0 10px;
}

.Nav_avatar__16du9 {
  font-size: 25px;
  line-height: 1;
}

.Logo_logo__1Uybs {
  display: flex;
  align-items: flex-start;
  color: #e31b24;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin: 0 10px;
}

@media (min-width: 768px) {
  .Logo_logo__1Uybs {
    font-size: 24px;
    margin: 0 20px;
  }
}

.Logo_n__1_ScN,
.Logo_e__9fegi,
.Logo_t__1N_eK,
.Logo_f__2zusE,
.Logo_l__2bQLj,
.Logo_i__3C0gW,
.Logo_x__1XSFC {
  line-height: 1;
}

.Logo_n__1_ScN,
.Logo_x__1XSFC {
  font-size: 115%;
}

.Logo_i__3C0gW,
.Logo_e__9fegi {
  font-size: 105%;
}

.ProfileSelector_selector__Icu21 {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  min-height: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-animation: ProfileSelector_fade-in__2mhTB 400ms;
          animation: ProfileSelector_fade-in__2mhTB 400ms;
}

.ProfileSelector_header__3Qbvd {
  font-size: 30px;
}

@media (min-width: 768px) {
  .ProfileSelector_selector__Icu21 {
    top: 70px;
  }

  .ProfileSelector_header__3Qbvd {
    font-size: 50px;
    margin-bottom: 30px;
  }
}

.ProfileSelector_profiles__2g-bD {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
}

@media (min-width: 321px) {
  .ProfileSelector_profiles__2g-bD {
    grid-template-columns: auto auto auto;
  }
}

@media (min-width: 768px) {
  .ProfileSelector_profiles__2g-bD {
    grid-column-gap: 20px;
  }
}

.ProfileSelector_button__2jMvD {
  padding: 10px 0;
  margin: 0;
  color: #808080;
  border: none;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  outline-color: #b3b3b3;
}

.ProfileSelector_avatar__3ovyx {
  font-size: 100px;
  line-height: 1;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .ProfileSelector_button__2jMvD {
    font-size: 18px;
  }

  .ProfileSelector_avatar__3ovyx {
    font-size: 120px;
    margin-bottom: 5px;
  }
}

@-webkit-keyframes ProfileSelector_fade-in__2mhTB {
  from {
    opacity: 0;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes ProfileSelector_fade-in__2mhTB {
  from {
    opacity: 0;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.Catalog_catalog__2k3t_ {
  margin: 0 20px;
}

@media (min-width: 768px) {
  .Catalog_catalog__2k3t_ {
    margin: 0 40px;
  }
}

.FeaturedMovie_hero__3IcED {
  position: relative;
  margin: 0 -20px;
  padding: 20px;
  background-size: cover;
  background-position: center;
}

.FeaturedMovie_container__1YCy2 {
  position: relative;
  display: flex;
  flex-direction: column;
  text-shadow: 0 0 8px rgba(20, 20, 20, 0.8);
  z-index: 99;
}

.FeaturedMovie_title__1Z50S {
  margin: 0 0 20px;
}

.FeaturedMovie_button__1tE-T {
  display: flex;
  background: rgba(20, 20, 20, 0.5);
  border: none;
  border-radius: 3px;
  padding: 10px 8px;
  color: #e5e5e5;
  width: 140px;
  font-size: 20px;
  justify-content: space-around;
  outline: none;
}

@media (min-width: 768px) {
  .FeaturedMovie_container__1YCy2 {
    max-width: 600px;
  }

  .FeaturedMovie_hero__3IcED {
    margin: 0 -40px;
    padding: 40px;
    min-height: 50vw;
  }

  .FeaturedMovie_title__1Z50S {
    font-size: 50px;
  }

  .FeaturedMovie_description__3_pf2 {
    max-width: 400px;
    font-size: 18px;
  }
}

@media (min-width: 1800px) {
  .FeaturedMovie_container__1YCy2 {
    justify-content: center;
  }
}

.FeaturedMovie_video__1fgiC {
  position: absolute;
  top: 0;
  left: 0;
}

.FeaturedMovie_gracefullyLeaving__2EUoq {
  opacity: 0 !important;
}

.PreviewVideo_video__2OrB8 {
  display: none;
  width: 100%;
  height: 100%;
  object-fit: fill;
  opacity: 0;
  -webkit-transition: opacity 1.5s;
  transition: opacity 1.5s;
}

.PreviewVideo_enter__3pGjA {
  opacity: 1;
}

@media (min-width: 768px) {
  .PreviewVideo_video__2OrB8 {
    display: block;
  }
}

.MovieCategory_category__bK5yq {
  margin-bottom: 40px;
}

.MovieCategory_movies___W32k {
  overflow-x: auto;
  overflow-y: hidden;
  margin: 0 -20px;
  /* For hiding scrollbar in firefox. */
  scrollbar-width: none;
}
.MovieCategory_movies___W32k::-webkit-scrollbar {
  /* For hiding scrollbar in webkit. */
  width: 0;
}

.MovieCategory_moviesInner__1ZWP1 {
  height: 190px;
  display: inline-flex;
  margin: -10px 0;
  padding: 0 20px;
  align-items: center;
}

@media (min-width: 768px) {
  .MovieCategory_movies___W32k {
    margin: 0 -40px;
  }
  .MovieCategory_moviesInner__1ZWP1 {
    padding: 0 40px;
  }
}

.MovieCategory_movieWrapper__2VPt4 {
  position: relative;
}

@media (max-width: 767px) {
  .MovieCategory_stackedOnMobile__3lQiL .MovieCategory_movies___W32k {
    margin: auto;
  }

  .MovieCategory_stackedOnMobile__3lQiL .MovieCategory_moviesInner__1ZWP1 {
    height: auto;
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  .MovieCategory_stackedOnMobile__3lQiL .MovieCategory_movieWrapper__2VPt4 {
    margin: 10px 0;
  }
}

.Movie_movie__1J78n {
  position: relative;
  width: 220px;
  height: 125px;
  margin-right: 5px;
  -webkit-transition: -webkit-transform 200ms;
  transition: -webkit-transform 200ms;
  transition: transform 200ms;
  transition: transform 200ms, -webkit-transform 200ms;
}

@media (min-width: 768px) {
  .Movie_movie__1J78n.Movie_hover__A8wl5 {
    box-shadow: 0 0 5px 1px rgba(20, 20, 20, 0.75);
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    z-index: 1;
  }
}

.Movie_moveIt__1kIDH {
  cursor: move;
}

.Movie_title__2pJUo {
  position: absolute;
  padding: 10px 4px 4px;
  margin: 0;
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    from(rgba(20, 20, 20, 0)),
    color-stop(50%, rgba(20, 20, 20, 0.5)),
    to(rgba(20, 20, 20, 0.75))
  );
  background: linear-gradient(
    180deg,
    rgba(20, 20, 20, 0) 0%,
    rgba(20, 20, 20, 0.5) 50%,
    rgba(20, 20, 20, 0.75) 100%
  );
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 14px;
}

.Movie_thumbnail__3QSJG {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.Movie_button__2FgaI {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  padding: 5px;
  margin: 0;
  outline: none;
}

.Movie_beginningButton__1iK1G {
  position: absolute;
  top: 30px;
  right: 0;
  background: none;
  border: none;
  padding: 5px;
  margin: 0;
}

@media (min-width: 768px) {
  .Movie_button__2FgaI {
    visibility: hidden;
    outline: none;
  }

  .Movie_beginningButton__1iK1G {
    visibility: hidden;
  }

  .Movie_movie__1J78n:hover .Movie_button__2FgaI {
    visibility: visible;
  }
}

.Movie_video__2p4KV {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-animation: Movie_fade-in__2Lwe2 1s;
          animation: Movie_fade-in__2Lwe2 1s;
}

@-webkit-keyframes Movie_fade-in__2Lwe2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes Movie_fade-in__2Lwe2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.DropZone_zone__3WJUx {
  position: absolute;
  width: 50%;
  height: 140%;
  left: -25%;
  top: -20%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 999;
  -webkit-transition: background-color 250ms;
  transition: background-color 250ms;
  border-radius: 6px;
}

.DropZone_last__3rHWW {
  left: auto;
  right: -25%;
}

.DropZone_hover__1uB94 {
  background-color: rgba(229, 229, 229, 0.4);
  box-shadow: 0 0 5px 0 rgba(229, 229, 229, 0.4);
}

.MyList_myList__2fkEA {
  margin: 0 20px;
}

.MyList_noTitlesYet__zAaJF {
  margin-top: 60px;
  font-size: 20px;
}

@media (min-width: 768px) {
  .MyList_myList__2fkEA {
    margin: 0 40px;
  }

  .MyList_noTitlesYet__zAaJF {
    text-align: center;
  }
}

